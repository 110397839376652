import React, { useState, useEffect } from "react";
import MessageBox from "../message-box";
import axios from "../../utils/request";
import Table from "../table";
import style from "./billDetailPop.module.css";

export default function billDetailPop({...props}) {
  const [data, setData] = useState([]);

  const COM_COLUMNS = [
    {
      title: "账单周期",
      dataIndex: "name",
      key: "name",
      width: 220,
      render: (value) => {
        value ? (<span>2020.09.09~2020.11.09</span>) : "-";
      },
    },
    {
      title: "账单金额",
      dataIndex: "name",
      key: "name",
      width: 220,
    },
  ];
  const others = [
    {
      title: "账单类型",
      dataIndex: "type",
      key: "type",
      width: 100,
    },
    {
      title: "明细",
      dataIndex: "detail",
      key: "detail",
      width: 100,
    },
  ];

  async function listRequest() {
    const { data } = await axios({
      url: "/designer/goodsList",
      params: {
        userId: 1540265819,
        status: 1,
      },
    });
    data.list.map((item, i) => {
      item.key = i + 1;
    });
    setData(data.list);
  }

  useEffect(() => {
    listRequest();
  }, []);

  return (
    <div className={style.billDetailBox}>
      <MessageBox {...props}>
        <h2>申请日期：2020.11.09</h2>
        <div className={style.tableWrap}>
          <Table
            title="保底版费账单"
            columns={[...COM_COLUMNS, ...others]}
            data={data}></Table>
        </div>
        <div className={style.tableWrap}>
          <Table
            title="分成账单"
            columns={COM_COLUMNS}
            data={data}></Table>
        </div>
        <div className={style.getMoneyBox}>
          <h3>提现金额：¥3243</h3>
          <ul>
            <li>
              <h4>保底版费账单：¥5555</h4>
              <p>已减版费定金：2833333元</p>
            </li>
            <li>
              <h4>分成账单：¥5555</h4>
              <p>已减合同版费总金额：2833333元</p>
            </li>
          </ul>
        </div>
      </MessageBox>
    </div>
  );
}
