import React, { useState, useRef } from "react";
import TabTable from "../../../components/tab-table";
import Cookies from "js-cookie";
import PublisherHeader from "../../../components/publish-header";
import UploadPop from "../../../components/uploadPop";
import BillDetailPop from "../../../components/billDetailPop";
import ApplyMoneyPop from "../../../components/ApplyMoneyPop";
import Cursor from "../../../components/cursor";
import { genImagePath } from "../../../utils/genImagePath";
import style from "./bill.module.css";

export default function ({ location }) {
  const [isUpload, setIsUpload] = useState(false);
  const [isLook, setIsLook] = useState(false);
  const [isApply, setIsApply] = useState(false);
  const outerParamsRef = useRef({ userId: +Cookies.get("userId") });
  const TABS = useRef([
    {
      value: "销售明细",
      num: 1,
      columns: [
        {
          title: "图",
          dataIndex: "imgList",
          key: "imgList",
          width: 100,
          render: (value) => (
            <img
              src={genImagePath(value[0])}
              width="57"
              height="57"
              alt="icy商品图"
            />
          ),
        },
        {
          title: "商品名称",
          dataIndex: "name",
          key: "name",
          width: 220,
        },
        {
          title: "发售日期",
          dataIndex: "saleDate",
          key: "saleDate",
        },
        {
          title: "销售周期",
          dataIndex: "saleDate",
          key: "saleCircel",
        },
        {
          title: "销售金额",
          dataIndex: "saleDate",
          key: "saleMoney",
        },
        {
          title: "分成金额",
          dataIndex: "saleDate",
          key: "saleDate",
        },
      ],
      filters: [{
        label: "发售日期",
        prop: "saleDate",
        type: "date",
      }, {
        label: "商品名称",
        prop: "name",
      }],
    },
    {
      value: "提现记录",
      num: 2,
      columns: [
        {
          title: "申请日期",
          dataIndex: "saleDate",
          key: "applyDate",
          width: 150,
        },
        {
          title: "提现金额",
          dataIndex: "hangTagPrice",
          key: "getMoney",
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "支付日期",
          dataIndex: "payDate",
          key: "payDate",
        },
        {
          title: "发票",
          dataIndex: "hangTagPrice",
          key: "receipt",
          render: (value) => {
            if (value) {
              return <span style={{color: "#EE7D05"}}>发票1234.jpg</span>;
            } else {
              return <span style={{color: "#EE7D05"}} onClick={() => {
                setIsUpload(true);
              }}>上传</span>;
            }
          },
        },
        {
          title: "账单明细",
          dataIndex: "hangTagPrice",
          key: "bill",
          render: () => <span onClick={() => setIsLook(true)}>查看</span>,
        },
      ],
      filters: [],
    },
  ]);


  return (
    <div className={style.registerWrap}>
      <Cursor></Cursor>
      <PublisherHeader pathName={location.pathname}></PublisherHeader>
      <div className={style.billWrap}>
        <div className={style.billBox}>
          <h2>ICY X mmm · 对账单</h2>
          <div className={style.billTable}>
            <div className={style.billLeft}>
              <ul>
                <li>累计</li>
                <li>
                  <h6>销售额</h6>
                  <p>¥343,324</p>
                </li>
                <li>
                  <h6>销量</h6>
                  <p>343324</p>
                </li>
                <li>
                  <h6>分成额</h6>
                  <p>¥343,324</p>
                </li>
              </ul>
              <ul>
                <li>当期</li>
                <li>
                  <h6>销售额</h6>
                  <p>¥343,324</p>
                </li>
                <li>
                  <h6>销量</h6>
                  <p>343324</p>
                </li>
                <li>
                  <h6>分成额</h6>
                  <p>¥343,324</p>
                </li>
              </ul>
            </div>
            <div className={style.billRight}>
              <h5>账单金额</h5>
              <p>¥343,324</p>
              <button onClick={() => setIsApply(true)}>申请提现</button>
            </div>
          </div>
        </div>
      </div>
      <div className={style.selectCloth} style={{minHeight: "calc(85vh - 405px)"}}>
        <TabTable
          tabs={TABS.current}
          defaultTabVal={1}
          outerParams={outerParamsRef.current}
          url="/designer/goodsList"
          emptyText="暂无明细"
        >
          <ul className={style.moneyRecord}>
            <li>已支付：￥34,888</li>
            <li>未支付：￥34,888</li>
          </ul>
        </TabTable>
      </div>
      <div className={style.footer}></div>
      {/* 上传发票 */}
      <UploadPop
        title="上传发票"
        setIsClosed={setIsUpload}
        isClosed={isUpload}></UploadPop>
      <BillDetailPop
        title="提现账单明细"
        setIsClosed={setIsLook}
        isClosed={isLook}></BillDetailPop>
      <ApplyMoneyPop
        title="申请提现"
        setIsClosed={setIsApply}
        isClosed={isApply}></ApplyMoneyPop>
    </div>
  );
}
