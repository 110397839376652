import React, { useState, useRef } from "react";
import RcCheckbox from "./lib/rc-checkbox";
import { BaseInput } from "../input";
import "./index.less";
import { useEffect } from "react";

export default function Checkbox({
  inputWidth = "360px",
  name,
  current,
  ...props
}) {
  const [val, setVal] = useState("");
  const checkboxRef = useRef(null);

  useEffect(() => {
    if (current?.input) {
      handleInputChange({ target: { value: current.value } });
    }
  }, [current]);

  function handleInputChange(e) {
    setVal(e.target.value);
    let checked = false;
    if (checkboxRef?.current) {
      ({ checked } = checkboxRef.current.state);
    }

    handleChange({
      target: {
        input: true,
        label: props.label,
        inputVal: e.target.value,
        checked,
      },
    });
  }

  function handleChange(e) {
    if (e.target.inputVal === undefined) {
      // eslint-disable-next-line
      e.target.inputVal = props.input ? val : null;
    }
    props.onChange(e);
  }

  return (
    <label style={props.styleMap}>
      <RcCheckbox
        ref={checkboxRef}
        {...props}
        name={name}
        onChange={handleChange}
      ></RcCheckbox>

      <span className="rc-checkbox-label">{props.label}</span>

      {props.input && (
        <BaseInput
          style={{ marginLeft: "15px", width: inputWidth }}
          value={val}
          onChange={handleInputChange}
        />
      )}
    </label>
  );
}
