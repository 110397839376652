import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import style from "./messageBox.module.css";

export default function MessageBox(props) {
  const [windowHeight, setWindowHeight] = useState(0);
  const boxEl = useRef(null);
  const contentEl = useRef(null);

  useEffect(() => {
    setWindowHeight($(window).height());
    if (boxEl.current.style.display !== "none" && contentEl.current.offsetHeight < (windowHeight - 150)) return;
    contentEl.current.style.overflowY = "scroll";
  }, []);

  return (
    <div style={{display: props.isClosed ? "block" : "none"}}>
      <div className={style.shade} onClick={() => props.setIsClosed(false)}></div>
      <div className={style.messageBox} ref={boxEl}>
        <div className={style.title}>
          {props.title}
          <div className={style.close} onClick={() => props.setIsClosed(false)}>X</div>
        </div>
        <div ref={contentEl} style={{maxHeight: `${windowHeight - 200}px`}}>
          <div className={style.messageContent}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}
