import React, { useState } from "react";
import MessageBox from "../message-box";
import Upload from "../../components/upload";
import style from "./uploadPop.module.css";

export default function UploadPop({...props}) {
  // const [reciept, setReciept] = useState({})
  const [EMSCompany, setEMSCompany] = useState("");
  const [EMSNum, setEMSNum] = useState("");

  return (
    <div className={style.popWrap}>
      <MessageBox {...props}>
        <h3>上传发票附件</h3>
        <p>*请注意：开票方应于收款方名称一致，并且开票金额应于本次申请总金额一致，否则将影响打款</p>
        <div className={style.uploadWrap}>
          <Upload
            accept="image/*"
            name="reciept"
            onChange={(e) => console.log(e)}
          />
        </div>
        <h3>发票邮寄信息</h3>
        <div className={style.postBox}>
          <p>*请尽快将发票邮寄到以下地址，并填写快递信息，财务会在收到发票后第一时间安排打款</p>
          <div className={style.postInfoWrap}>
            <div className={style.postInfo}>
              <h4>填写邮寄信息</h4>
              <div className={style.postInput}>
                <label htmlFor=""><span>*</span>物流公司</label>
                <input
                  value={EMSCompany}
                  type="text"
                  onChange={(e) => setEMSCompany(e.target.value)}/>
              </div>
              <div className={style.postInput}>
                <label htmlFor=""><span>*</span>物流单号</label>
                <input
                  value={EMSNum}
                  type="text"
                  onChange={(e) => setEMSNum(e.target.value)}/>
              </div>
            </div>
            <div className={style.postInfo} style={{marginLeft: "40px"}}>
              <h4>邮寄地址</h4>
              <ul>
                <li>收件人：ICY财务</li>
                <li>手机号：18817380862</li>
                <li>地址：上海杨浦区政府路18号波司大厦17楼财务办公室</li>
              </ul>
            </div>
          </div>
        </div>
        <button>确认</button>
      </MessageBox>
    </div>
  );
}
