import React, { useState, useEffect } from "react";
import axios from "../../utils/request";
import MessageBox from "../message-box";
import Select from "../select/index";
import Table from "../table";
import Checkbox from "../checkbox-group/checkbox";
import style from "./applyMoneyPop.module.less";
const selectList = [];
const selectList2 = [];

export default function applyMoneyPop({...props}) {
  const [data, setData] = useState([]);
  const [season, setSeason] = useState("春");
  // const [a, setA] = useState([])

  function handleCheckbox(e, row, list) {
    if (e.target.checked) {
      list.push(row);
    } else {
      const index = list.findIndex((item) => item.key === row.key);
      list.splice(index, 1);
    }
  }


  const COM_COLUMNS = [
    {
      title: "账单周期",
      dataIndex: "name",
      key: "name",
      width: 220,
      render: (value) => {
        value ? (<span>2020.09.09~2020.11.09</span>) : "-";
      },
    },
    {
      title: "账单金额",
      dataIndex: "name",
      key: "name",
      width: 220,
    },
  ];
  const others = [
    {
      title: "账单类型",
      dataIndex: "type",
      key: "type",
      width: 100,
    },
    {
      title: "明细",
      dataIndex: "detail",
      key: "detail",
      width: 100,
    },
  ];

  async function listRequest() {
    const { data } = await axios({
      url: "/designer/goodsList",
      params: {
        userId: 1540265819,
        status: 1,
      },
    });
    data.list.map((item, i) => {
      item.key = i + 1;
    });
    setData(data.list);
  }

  useEffect(() => {
    listRequest();
  }, []);

  return (
    <div className={style.billDetailBox}>
      <MessageBox {...props}>
        <div className={style.applyTitle}>
          选择提现账单
          <div className={style.titleRight}>
            <div style={{position: "relative"}} className={style.selectWrap}>
              <label htmlFor="">季度：</label>
              <Select
                value={season}
                defaultValue={season}
                name="season"
                options={[
                  {
                    label: "春",
                    value: "春",
                  },
                  {
                    label: "夏",
                    value: "夏",
                  },
                  {
                    label: "秋",
                    value: "秋",
                  },
                  {
                    label: "冬",
                    value: "冬",
                  },
                ]}
                onChange={(e) => setSeason(e)}
              />
            </div>
            <div className={style.remindInfo}>
              <span></span>
              未到达可提现日期
              <i>i</i>
            </div>
          </div>
        </div>
        <div className={style.tableWrap}>
          <Table
            title="保底版费账单"
            columns={[
              {
                key: "checkbox",
                width: 100,
                render: (value, row) => (
                  <Checkbox
                    onChange={(e) => handleCheckbox(e, row, selectList)}>
                  </Checkbox>
                ),
              },
              ...COM_COLUMNS,
              ...others]}
            data={data}></Table>
        </div>
        <div className={style.tableWrap}>
          <Table
            title="分成账单"
            thinBorder
            columns={[
              {
                key: "checkbox",
                width: 100,
                render: (value, row) => (
                  <Checkbox
                    onChange={(e) => handleCheckbox(e, row, selectList2)}>
                  </Checkbox>
                ),
              },
              ...COM_COLUMNS]}
            data={data}></Table>
        </div>
        <div className={style.getMoneyBox}>
          <h3>总计可提现金额：¥0</h3>
          <ul>
            <li>
              <h4>保底版费账单：¥5555<span>还差 <strong>¥223,222</strong> 可提现</span></h4>
              <p>
                保底版费结算 &gt; 版费定金才可提现<br/>
                版费定金：2833333元
              </p>
            </li>
            <li>
              <h4>分成账单：¥5555<span>还差 <strong>¥223,222</strong> 可提现</span></h4>
              <p>
                分成账单金额 &gt; 合同版费总金额才可提现<br/>
                合同版费总金额：2833333元备份
              </p>
            </li>
          </ul>
        </div>
        <button className={style.applyButton}>提交申请</button>
      </MessageBox>
    </div>
  );
}
